import {
  bannerIcon,
  fourColumns,
  logos,
  solution,
} from '../../../fragments/fragments'

import Addition from '../../../components/technical-benchmark/addition'
import Banner from '../../../components/reusables/banner'
import Centered from '../../../components/reusables/centered'
import Layout from '../../../components/layout'
import Multiplcation from '../../../components/technical-benchmark/multiplication'
import React from 'react'
import Solutions from '../../../components/reusables/solutions'
import Tabs from '../../../components/reusables/tabs'
import { graphql } from 'gatsby'
import { injectIntl } from 'react-intl'
import { withIntl } from '../../../i18n'

const UserEngagementSolutions = ({ data }) => {
  const WrappedTabs = Tabs([<Addition />, <Multiplcation />])
  return (
    <Layout>
      <Banner data={data} shade={'grey'} />
      <Centered index="0" data={data} shade={'light'} />
      <Solutions index="0" data={data} shade={'grey'} />
    </Layout>
  )
}

export default withIntl(UserEngagementSolutions)

export const imagesQuery = graphql`
  query {
    bannerCube: file(relativePath: { eq: "hero/bannerCube@3x.png" }) {
      ...bannerIcon
    }
    gateway_bookmark: file(
      relativePath: { eq: "regular/gateway-bookmark@3x.png" }
    ) {
      ...solution
    }
    gateway_stats: file(relativePath: { eq: "regular/gateway-stats@3x.png" }) {
      ...solution
    }
    gateway_api: file(relativePath: { eq: "regular/gateway-api@3x.png" }) {
      ...solution
    }
    shortLastSection: file(relativePath: { eq: "hero/shortLastSection@2x.png" }) {
      childImageSharp {
        fluid(maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
